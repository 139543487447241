"use client";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { Button } from "@/components/ui/button";
import MeshGradient from "mesh-gradient.js";
import { HexColorPicker } from "react-colorful";
import { ScrollArea } from "@/components/ui/scroll-area";
import { RefreshCw, X } from "lucide-react";
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useId,
  useMemo,
  useState
} from "react";
import { CommonTooltip } from "@/components/custom/common-tooltip";
import Image from "next/image";
import { IconColorSwatch } from "@tabler/icons-react";
import { generateRandomColor } from "@/lib/colour";
import { v4 as uuidv4 } from "uuid";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs-variant2";
import { Text } from "@/components/ui/text";
import Unsplash from "@/components/image/unsplash";
import { Dropzone } from "@/components/ui/dropzone";
import { MIME_TYPES } from "@/types/mime-types";
import { maxThirtyMb } from "@/config/max-size";
import { useImageCropModal } from "@/stores/use-image-crop-store";
import Divider from "@/components/ui/divider";
import { useImageSelector } from "@/stores/use-image-selector.store";

const initColours = [
  { colours: ["#EB75B6", "#DDF3FF", "#6E3DEB", "#C92F3C"], meshId: 757 },
  { colours: ["#4DFFB2", "#DDF3FF", "#12F5DA", "#507CE2"], meshId: 833 },
  { colours: ["#FF4E4E", "#FFF2DB", "#FBFF1B", "#FF7025"], meshId: 759 },
  { colours: ["#FF004C", "#FFDB9E", "#FBFF1B", "#FF7025"], meshId: 98 },
  { colours: ["#FF004C", "#FFDB9E", "#01D6B3", "#66D1FF"], meshId: 679 },
  { colours: ["#0F32FF", "#6F3366", "#B8FFF7", "#66D1FF"], meshId: 527 },
  { colours: ["#1BC736", "#FDFF80", "#17D6FB", "#008F58"], meshId: 257 },
  { colours: ["#090238", "#8934FF", "#FF14BD", "#DCC5FF"], meshId: 754 },
  { colours: ["#C996CC", "#916BBF", "#3D2C8D", "#1C0C5B"], meshId: 370 },
  { colours: ["#FFEDD3", "#FCD2D1", "#FE8F8F", "#FF5C58"], meshId: 440 },
  { colours: ["#F56FAD", "#C32BAD", "#7027A0", "#1DB9C3"], meshId: 189 }
];

const tabs = [
  { label: "Unsplash", value: "unsplash" },
  { label: "Mesh Gradients", value: "meshGradients" }
];

export interface ratio {
  COMMUNITY: "homeBasicSection";
  CALENDAR: "calendarBanner";
  EVENT: "eventBanner";
  USER: "profile";
}

export const cropperRatio: ratio = {
  COMMUNITY: "homeBasicSection",
  CALENDAR: "calendarBanner",
  EVENT: "eventBanner",
  USER: "profile"
};

export const ImageSelectorModal = ({ isOverLay = false }: { isOverLay?: boolean }) => {
  const { isOpen: modelOpen, onClose: onModalClose, type: type1, data: data1 } = useModal();
  const {
    isOpen: isImageOpen,
    onClose: onIsSelectClose,
    type: type2,
    data: data2
  } = useImageSelector();
  const { isOpen, onClose, type, data } = isOverLay
    ? { isOpen: isImageOpen, onClose: onIsSelectClose, type: type2, data: data2 }
    : { isOpen: modelOpen, onClose: onModalClose, type: type1, data: data1 };
  const { callback, openCroper = true, unsplashType, cropRatio } = data;

  const [currentTab, setCurrentTab] = useState("unsplash");

  const { onOpen, onClose: onCropperClose } = useImageCropModal();

  const isModalOpen = isOpen && type === "imageSelector";

  const handleClose = () => {
    onClose();
  };

  const handleFileUpload = (file: File) => {
    if (openCroper) {
      onOpen({
        img: file as File,
        for: cropRatio
          ? cropperRatio[cropRatio ? (cropRatio as keyof ratio) : (unsplashType as keyof ratio)]
          : "eventBanner",
        callback: async (f) => {
          if (file && callback) {
            onCropperClose();
            onClose();
            await callback?.(f);
          }
        }
      });
    } else {
      callback?.(file);
      onClose();
    }
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className="max-w-full-50px lg:w-[50%] w-[80%] overflow-hidden p-0 bg-none border-none backdrop-blur-none shadow-none"
        style={{
          height: "calc(100dvh - 90px)",
          background: "transparent"
        }}
        isShowCloseBtn={false}
      >
        <div className="w-full max-h-full flex flex-col overflow-hidden">
          <div className="rounded-lg bg-modal overflow-hidden  shadow-lg backdrop-blur-20 flex flex-col my-auto">
            <div className="flex-shrink-0 px-4 py-4">
              <DialogHeader className="flex-shrink-0">
                <div className="flex gap-2">
                  <DialogTitle>Choose Image</DialogTitle>
                  <DialogClose className="ms-auto">
                    <X className="h-4 w-4" />
                  </DialogClose>
                </div>
                <div className="mt-m">
                  <Divider />
                </div>
              </DialogHeader>
            </div>
            <Dropzone
              accept={{
                [MIME_TYPES.png]: [],
                [MIME_TYPES.gif]: [],
                [MIME_TYPES.jpeg]: [],
                [MIME_TYPES.svg]: [],
                [MIME_TYPES.webp]: [],
                [MIME_TYPES.avif]: [],
                [MIME_TYPES.heic]: []
              }}
              onDrop={(files) => handleFileUpload(files[0])}
              multiple={false}
              className="inline-block px-4 "
              noKeyboard
              cropRatio={cropRatio || "COMMUNITY"}
              maxSize={maxThirtyMb}
            >
              <div className="bg-zinc-100 rounded-md w-full p-m content-center border-2 border-dashed border-gray-300 transition-all duration-200">
                <div className="w-full content-center ">
                  <Text className="text-sm text-center font-bold text-gray-700 dark:text-gray-300">
                    Drop your file here or click to upload
                  </Text>
                  <Text className="text-xs text-center mt-xs  text-gray-500  dark:text-gray-400">
                    Or select an image below. Ideal aspect ratio: 4:3.
                  </Text>
                </div>
              </div>
            </Dropzone>
            <Tabs
              value={currentTab}
              onValueChange={(v) => setCurrentTab(v)}
              className="!rounded-6 w-full relative"
            >
              <TabsList className="bg-transparent justify-start  my-s mx-auto rounded-none">
                {tabs.map((tab) => (
                  <TabsTrigger
                    value={tab.value}
                    key={tab.value}
                    className="rounded-none mr-2xl !pt-3 pb-3 data-[state=active]:border-black data-[state=active]:bg-transparent border-transparent border-b-1 data-[state=active]:shadow-none data-[state=active]:backdrop-blur-0 font-normal"
                  >
                    <Text
                      variant={currentTab === tab.value ? "body_one_strong" : "body_one"}
                      as="span"
                    >
                      {tab.label}
                    </Text>
                  </TabsTrigger>
                ))}
              </TabsList>
            </Tabs>
            {currentTab === "meshGradients" ? (
              <MeshGradientPicker onClose={onClose} callback={callback} />
            ) : null}
            {currentTab === "unsplash" ? (
              <Unsplash
                type={unsplashType}
                cropRatio={cropRatio || "COMMUNITY"}
                onClose={onClose}
                callback={callback}
              />
            ) : null}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

type SingleGradient = { colours: string[]; meshId: number };

const MeshGradientPicker = ({
  callback,
  onClose
}: {
  callback?: (file: any) => void;
  onClose: () => void;
}) => {
  const [initColour, setInitColour] = useState<SingleGradient | null>(null);
  const [colours, setColours] = useState<SingleGradient[]>(initColours);

  useCallback(() => {
    const colours = Array.from({ length: 4 }, generateRandomColor);
    const meshId = Math.floor(Math.random() * 1000);
    return { colours, meshId };
  }, []);

  const randomizeColors = useCallback(() => {
    const colours = initColours.map(() => ({
      colours: Array.from({ length: 4 }, generateRandomColor),
      meshId: Math.floor(Math.random() * 1000)
    }));
    setColours(colours);
  }, []);

  const getCanvasFile = useCallback(async (id: string) => {
    const canvas = document.getElementById(id) as HTMLCanvasElement;
    if (!canvas) return;
    const blob: Blob | null = await new Promise((resolve) =>
      canvas.toBlob(resolve, "image/png", 1)
    );
    if (!blob) return;
    const file = new File([blob], uuidv4(), { type: "image/png" });
    onClose();
    await callback?.(file);
  }, []);

  return (
    <Fragment>
      {initColour ? (
        <div className="flex-shrink-1 overflow-hidden">
          <ScrollArea className="h-full px-4">
            <div className="w-full flex-shrink-1 flex flex-col gap-3">
              <div className="grid grid-cols-1 gap-2 w-full">
                <CustomiseMeshGradientComponent
                  initColour={initColour}
                  setInitColour={setInitColour}
                  getCanvasFile={getCanvasFile}
                />
              </div>
            </div>
          </ScrollArea>
        </div>
      ) : (
        <div className="flex-shrink-1 overflow-hidden">
          <ScrollArea className="h-full px-4 pb-3">
            <div className="w-full flex-shrink-1 flex flex-col gap-3">
              <div className="grid grid-cols-4 gap-2 w-full">
                <div
                  role="button"
                  onClick={randomizeColors}
                  className="w-full rounded-8 overflow-hidden relative border-dashed border-1 border-black/20"
                >
                  <div className="relative w-full h-full flex items-center justify-center">
                    <div className="bg-white/70 absolute top-0 left-0 right-0 bottom-0 z-30" />
                    <Image src="/static/images/mesh-gradient.jpeg" fill alt="" className="z-20" />
                    <div className="w-10 h-10 relative z-40 flex items-center justify-center bg-white rounded-full">
                      <IconColorSwatch size={22} stroke={1} />
                    </div>
                  </div>
                </div>
                {colours.map((colour, index) => (
                  <div className="w-full" key={index}>
                    <MeshGradientSingle colour={colour} setInitColour={setInitColour} />
                  </div>
                ))}
              </div>
            </div>
          </ScrollArea>
        </div>
      )}
    </Fragment>
  );
};

const CustomiseMeshGradientComponent = ({
  initColour,
  setInitColour,
  getCanvasFile
}: {
  initColour: SingleGradient;
  setInitColour: Dispatch<SetStateAction<SingleGradient | null>>;
  getCanvasFile: (id: string) => Promise<void>;
}) => {
  const [colour, setColour] = useState<SingleGradient>(initColour);
  const gradient = useMemo(() => new MeshGradient(), []);
  const canvasId = useId();

  const id = useMemo(() => canvasId.replace(/([:.])/g, ""), [canvasId]);

  useEffect(() => {
    gradient.initGradient(`#${id}`, colour.colours);
    gradient?.setCanvasSize(1200, 900);
    gradient?.changePosition(colour.meshId);
  }, [canvasId, gradient, id, colour]);

  const regenerate = useCallback(() => {
    const meshId = Math.floor(Math.random() * 1000);
    setColour((c) => ({ ...c, meshId }));
  }, []);

  const changeColour = useCallback((index: number, color: string) => {
    setColour((c) => {
      const colours = [...c.colours];
      colours[index] = color;
      return { ...c, colours };
    });
  }, []);

  return (
    <div className="grid grid-cols-4 gap-2 w-full pb-4">
      <div className="col-span-4 max-h-[45vh] mx-auto w-full">
        <canvas id={id} className="rounded-8 mx-auto h-full w-full" />
      </div>

      <div className="md:flex col-span-4 justify-between w-full py-3">
        <div className="mx-auto flex flex-wrap items-center gap-2 w-full">
          {colour.colours.map((c, index) => (
            <Fragment key={index}>
              <CommonTooltip
                render={(attrs) => (
                  <div tabIndex={-1} {...attrs}>
                    <HexColorPicker color={c} onChange={(color) => changeColour(index, color)} />
                  </div>
                )}
                interactive
                placement="bottom-start"
                maxWidth="none"
                arrow={false}
                trigger="click"
              >
                <button className="w-10 h-10 rounded-full border-[2px] border-solid border-black p-[2px]">
                  <div className="w-full h-full rounded-full" style={{ backgroundColor: c }}></div>
                </button>
              </CommonTooltip>
            </Fragment>
          ))}
          <Button type="button" className="rounded-full p-[7px]" onClick={regenerate}>
            <RefreshCw className="text-white" />
          </Button>
        </div>

        <div className=" w-[250px] mx-auto mt-m md:mt-0 flex flex-wrap items-end justify-end gap-2">
          <Button type="button" variant={"secondary"} onClick={() => setInitColour(null)}>
            Back
          </Button>

          <Button type="button" onClick={() => getCanvasFile(id)}>
            Select
          </Button>
        </div>
      </div>
    </div>
  );
};

const MeshGradientSingle = ({
  colour,
  setInitColour
}: {
  colour: SingleGradient;
  setInitColour: Dispatch<SetStateAction<SingleGradient | null>>;
}) => {
  const gradient = useMemo(() => new MeshGradient(), []);
  const canvasId = useId();

  const id = useMemo(() => canvasId.replace(/([:.])/g, ""), [canvasId]);

  useEffect(() => {
    const canvas = document.getElementById(id);
    if (canvas && typeof window !== "undefined") {
      gradient.initGradient(`#${id}`, colour.colours);
      gradient?.setCanvasSize(1200, 900);
      gradient?.changePosition(colour.meshId);
    }
  }, [canvasId, gradient, id, colour]);

  return (
    <canvas
      role="button"
      onClick={() => setInitColour(colour)}
      id={id}
      className="rounded-8 w-full h-full"
    />
  );
};
