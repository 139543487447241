"use client";

import { useModal } from "@/stores/use-modal-store";
import dynamic from "next/dynamic";
import { Fragment, useEffect } from "react";
import EditorLoader from "@/components/editor-loader";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useCrypto } from "@/providers/crypto-provider";
import { useConversationKeys } from "@/hooks/use-conversations-query";

const MessageEditor = dynamic(() => import("@/components/editor/message-editor"), {
  ssr: false,
  loading: EditorLoader
});

export const EditThreadModal = () => {
  const { isOpen, onClose, type, data } = useModal();

  const { thread } = data;

  const { privateKey, privateKeys, lastSyncVersion } = useCrypto();

  const { data: conversationKeys, refetch } = useConversationKeys(thread!.conversationId);

  const isModalOpen = isOpen && type === "editThread";

  const handleClose = () => onClose();

  useEffect(() => {
    if (!privateKey || !conversationKeys?.hex || !privateKeys?.length) return;
    if (conversationKeys?.hex?.length !== privateKeys?.length) {
      refetch();
      return;
    }
  }, [conversationKeys?.hex, privateKey, privateKeys]);

  if (!thread) return <Fragment />;

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[750px] p-4 overflow-visible">
        <DialogHeader>
          <DialogTitle>Edit Thread</DialogTitle>
        </DialogHeader>
        <MessageEditor
          msg={thread}
          type={data.type}
          query={{
            conversationId: thread!.conversationId,
            messageId: thread!.messageId,
            serverId: thread!.serverId
          }}
          myEncryptionKey={conversationKeys?.hex}
          privateKeys={privateKeys}
          lastSyncVersion={lastSyncVersion}
          mentionEnabled={true}
        />
      </DialogContent>
    </Dialog>
  );
};
