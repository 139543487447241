"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Check, Copy } from "lucide-react";
import { useClipboard } from "@mantine/hooks";
import Image from "next/image";
import { useCallback, useEffect, useState } from "react";
import { Text } from "@/components/ui/text";
import qs from "query-string";

export const ShareModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { copy, copied } = useClipboard({ timeout: 10000 });
  const { share } = data;

  const isModalOpen = isOpen && type === "share";

  const handleClose = () => {
    onClose();
  };

  const telegram = useCallback(() => {
    if (!share) return;
    const url = qs.stringifyUrl({
      url: "https://t.me/share/url",
      query: { url: share.link, text: share.title }
    });
    window.open(url);
  }, [share]);

  const mail = useCallback(() => {
    if (!share) return;
    const url = qs.stringifyUrl({
      url: "mailto:",
      query: {
        subject: share.title,
        body: share.for !== "Community" ? share.link + "\n" + share.description : share.description
      }
    });

    window.open(url);
  }, [share]);

  const whatsapp = useCallback(() => {
    if (!share) return;
    const url = qs.stringifyUrl({
      url: "https://wa.me",
      query: { text: share.link }
    });

    window.open(url);
  }, [share]);

  const linkedin = useCallback(() => {
    if (!share) return;
    const url = qs.stringifyUrl({
      url: "https://www.linkedin.com/shareArticle",
      query: { url: share.link, title: share.title, mini: true }
    });
    window.open(url);
  }, [share]);

  const facebook = useCallback(() => {
    if (!share) return;
    const url = qs.stringifyUrl({
      url: "https://www.facebook.com/sharer.php",
      query: { u: share.link, title: share.title, mini: true }
    });
    window.open(url);
  }, [share]);

  const x = useCallback(() => {
    if (!share) return;
    const url = qs.stringifyUrl({
      url: "https://x.com/intent/tweet",
      query: { url: share.link, text: share.title }
    });
    window.open(url);
  }, [share]);

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[500px] p-xl flex flex-col !gap-2xl max-h-dvh-90px">
        <DialogHeader className="sticky">
          <DialogTitle>Share This {share?.for ? share?.for : "Community"}</DialogTitle>
        </DialogHeader>

        <div className="space-y-4 flex flex-col gap-2xl py-m">
          <div className="flex items-center justify-between flex-wrap gap-3">
            <div className="flex justify-center flex-col gap-[6px]">
              <button
                onClick={whatsapp}
                className="w-12 h-12  outline-none rounded-full flex items-center justify-center"
              >
                <Image
                  src="/static/images/social/whatsapp.svg"
                  width={80}
                  height={80}
                  className="w-full h-full object-contain"
                  alt="X"
                />
              </button>
              <Text variant="caption_one" className="text-center ">
                Whatsapp
              </Text>
            </div>

            <div className="flex justify-center flex-col gap-[6px]">
              <button
                onClick={x}
                className="w-10 h-10 outline-none rounded-full flex items-center justify-center"
              >
                <Image
                  src="/static/images/social/X.svg"
                  width={30}
                  height={30}
                  className="w-full h-full object-contain"
                  alt="X"
                />
              </button>
              <Text variant="caption_one" className="text-center ">
                X
              </Text>
            </div>

            <div className="flex justify-center flex-col gap-[6px]">
              <button
                onClick={linkedin}
                className="w-12 h-12  outline-none rounded-full   flex items-center justify-center"
              >
                <Image
                  src="/static/images/social/linkedin.svg"
                  width={30}
                  height={30}
                  className="w-full h-full object-contain"
                  alt="Linkedin"
                />
              </button>
              <Text variant="caption_one" className="text-center ">
                LinkedIn
              </Text>
            </div>
            <div className="flex justify-center flex-col gap-[6px]">
              <button
                onClick={facebook}
                className="w-12 h-12  outline-none rounded-full flex items-center justify-center"
              >
                <Image
                  src="/static/images/social/facebook.svg"
                  width={30}
                  height={30}
                  className="w-full h-full object-contain"
                  alt="Facebook"
                />
              </button>
              <Text variant="caption_one" className="text-center ">
                Facebook
              </Text>
            </div>
            <div className="flex justify-center flex-col gap-[6px]">
              <button
                onClick={telegram}
                className="w-12 h-12  outline-none rounded-full flex items-center justify-center"
              >
                <Image
                  src="/static/images/social/telegram.svg"
                  width={30}
                  height={30}
                  className="w-full h-full object-contain"
                  alt="Telegram"
                />
              </button>
              <Text variant="caption_one" className="text-center ">
                Telegram
              </Text>
            </div>
            <div className="flex justify-center flex-col gap-[6px]">
              <button
                onClick={mail}
                className="w-12 h-12  outline-none rounded-full  flex items-center justify-center"
              >
                <Image
                  src="/static/images/social/mail.svg"
                  width={30}
                  height={30}
                  className="w-full h-full object-contain"
                  alt="Mail"
                />
              </button>
              <Text variant="caption_one" className="text-center ">
                Mail
              </Text>
            </div>
          </div>
          <div className="w-full">
            <Input
              placeholder="Share the link"
              label="Share the link"
              value={share?.link}
              readOnly
              rightSection={
                <button className="mt-1" onClick={() => copy(share?.link)}>
                  {copied ? (
                    <Check className="w-4 h-4 text-green-700" />
                  ) : (
                    <Copy className="w-4 h-4 " />
                  )}
                </button>
              }
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
