"use client";
import React, { Fragment, useEffect, useRef } from "react";
import Intercom from "@intercom/messenger-js-sdk";
import { env } from "@/env";
import { useSession } from "next-auth/react";
import { useDevice } from "@/hooks/use-device";
import posthog from "posthog-js";
import { User } from "@/types/entities";

export default function IntercomMessenger() {
  const { data: user, status: authStatus } = useSession();
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const { isMobile } = useDevice();

  const handelPostHog = (user: { user: User }) => {
    posthog.capture("Intercom_feedback", {
      id: user.user?.id || "",
      firstName: user.user?.firstName || "",
      lastName: user.user?.lastName || "",
      email: user.user?.email || "",
      phoneNumber: user.user?.phoneNumber || "",
      sessionUrl: posthog.get_session_replay_url(),
      sessionId: posthog.get_session_id()
    });
  };

  useEffect(() => {
    if (authStatus !== "authenticated" || !user.user.id) return;

    Intercom({
      app_id: env.NEXT_PUBLIC_INTERCOM_API_KEY,
      hide_default_launcher: true,
      horizontal_padding: 20,
      vertical_padding: 20
    });

    if (isMobile == false) {
      // Hide default Intercom launcher using CSS
      const intercomLauncher = document.querySelector(".intercom-lightweight-app-launcher-icon");
      if (intercomLauncher instanceof HTMLElement) {
        intercomLauncher.style.display = "none";
      }

      const createButton = () => {
        if (buttonRef.current) return;

        const button = document.createElement("button");
        buttonRef.current = button;
        button.style.position = "fixed";
        button.style.bottom = "140px";
        button.style.right = "60px";
        button.style.width = "48px";
        button.style.height = "48px";
        button.style.padding = "22px";
        button.style.borderRadius = "60%";
        button.style.border = "none";
        button.style.backgroundColor = "black";
        button.style.backgroundImage = "url('/static/images/keyLogo.png')";
        button.style.backgroundSize = "26px 26px";
        button.style.backgroundRepeat = "no-repeat";
        button.style.backgroundPosition = "center";
        button.style.cursor = "move";
        button.style.zIndex = "1000";
        document.body.appendChild(button);

        let isDragging = false;
        let startX: number, startY: number;
        let offsetX: number, offsetY: number;

        const mouseDownHandler = (e: MouseEvent) => {
          if (e.target === button) {
            isDragging = true;
            startX = e.clientX;
            startY = e.clientY;
            offsetX = e.clientX - button.getBoundingClientRect().left;
            offsetY = e.clientY - button.getBoundingClientRect().top;
          }
        };

        const mouseMoveHandler = (e: MouseEvent) => {
          if (!isDragging) return;
          button.style.left = `${e.clientX - offsetX}px`;
          button.style.top = `${e.clientY - offsetY}px`;
          button.style.right = "auto";
          button.style.bottom = "auto";
        };

        const mouseUpHandler = (e: MouseEvent) => {
          if (isDragging) {
            isDragging = false;
            const deltaX = Math.abs(e.clientX - startX);
            const deltaY = Math.abs(e.clientY - startY);
            if (deltaX < 5 && deltaY < 5 && window.Intercom) {
              window.Intercom("show");
            }
          }
        };

        document.addEventListener("mousedown", mouseDownHandler);
        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);

        button.addEventListener("mouseover", () => (button.style.transform = "scale(1.1)"));
        button.addEventListener("mouseout", () => (button.style.transform = "scale(1)"));
      };

      createButton();

      const handleIntercomVisibility = (isVisible: boolean) => {
        if (buttonRef.current) {
          buttonRef.current.style.display = isVisible ? "none" : "";
        }
      };

      if (window.Intercom) {
        window.Intercom("onShow", () => {
          if (user.user?.email) {
            handelPostHog(user);
          }
          handleIntercomVisibility(true);
        });
        window.Intercom("onHide", () => handleIntercomVisibility(false));
      }

      return () => {
        document.removeEventListener("mousedown", () => {});
        document.removeEventListener("mousemove", () => {});
        document.removeEventListener("mouseup", () => {});

        if (window.Intercom) {
          window.Intercom("offShow", () => {
            if (user.user?.email) {
              handelPostHog(user);
            }
            handleIntercomVisibility(true);
          });
          window.Intercom("offHide", () => handleIntercomVisibility(false));
        }
      };
    }
  }, [authStatus, user, isMobile]);

  return <Fragment />;
}
