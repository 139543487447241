"use client";

import * as yup from "yup";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { useFormik } from "formik";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { Calendar } from "@/components/ui/calendar";
import { DatepickerTooltip } from "@/components/custom/datepicker-tooltip";
import moment from "moment";
import clientApi from "@/api-client/client";
import { useParams } from "next/navigation";
import { HomeSections } from "@key.ai/enum";
import { ApiResponse } from "@/types/api";
import { toast } from "sonner";
import { isValidUrl } from "@/lib/url";
import { useEffect } from "react";

const Schema = yup.object({
  title: yup.string().required("This field is required.").trim(),
  url: yup
    .string()
    .required("This field is required.")
    .test("is-url-valid", "Please enter valid URL.", (value) => isValidUrl(value))
});

export const ExternalLinkModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const params = useParams<{ domain: string }>();
  const { callback, externalLinks } = data;

  const isModalOpen = isOpen && type === "externalLinks";

  const formik = useFormik({
    initialValues: {
      title: "",
      url: "",
      date: null
    },
    validationSchema: Schema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        if (!externalLinks) return;
        values.url = values.url.startsWith("http") ? values.url : `https://${values.url}`;
        await clientApi
          .post(`api/v1/${params.domain}/server-sections`, {
            json: {
              title: externalLinks.section.title,
              clientId: externalLinks.clientId,
              json: {
                items:
                  typeof externalLinks.index === "number" && externalLinks.index > -1
                    ? externalLinks.section.items.map((item, i) =>
                        typeof externalLinks.index === "number" && i === externalLinks.index
                          ? values
                          : item
                      )
                    : [...externalLinks.section.items, values]
              },
              order: Number(externalLinks.order),
              section: HomeSections.Links,
              attachments: []
            }
          })
          .json<ApiResponse>();
        callback?.(values);
        resetForm();
        onClose();
        toast.success("Saved!");
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (externalLinks && externalLinks.index !== -1 && typeof externalLinks.index === "number") {
      formik.setFieldValue("title", externalLinks.section.items[externalLinks.index].title);
      formik.setFieldValue("url", externalLinks.section.items[externalLinks.index].url);
      formik.setFieldValue("date", externalLinks.section.items[externalLinks.index].date);
    }
  }, [externalLinks]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[650px] p-4 max-h-[calc(100dvh - 90px)]">
        <DialogHeader className="sticky">
          <DialogTitle>External Links</DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4 flex flex-col gap-2xl">
          <div className="w-full">
            <Input
              label="URL"
              placeholder="URL"
              name="url"
              onChange={formik.handleChange}
              value={formik.values.url}
              error={(formik.touched.url && formik.errors.url) as boolean}
              errorMessage={formik.errors.url}
              errorOutline={(formik.touched.url && formik.errors.url) as boolean}
            />
          </div>

          <div className="w-full">
            <Textarea
              placeholder="Title"
              name="title"
              label="Title"
              onChange={formik.handleChange}
              value={formik.values.title}
              error={(formik.touched.title && formik.errors.title) as boolean}
              errorMessage={formik.errors.title}
              errorOutline={(formik.touched.title && formik.errors.title) as boolean}
            />
          </div>

          <div className="w-full">
            <DatepickerTooltip
              trigger="click"
              render={(attrs, content, instance) => (
                <div tabIndex={-1} {...attrs} className="bg-white rounded-sm">
                  <Calendar
                    mode="single"
                    selected={formik.values.date}
                    onSelect={(date: Date) => {
                      formik.setFieldValue("date", date);
                      instance?.hide();
                    }}
                  />
                </div>
              )}
              interactive
              placement="auto-start"
              maxWidth="none"
              arrow={false}
              offset={[0, 5]}
              hideOnClick
            >
              <Input
                label="Date"
                placeholder="Date"
                name="date"
                readOnly
                value={formik.values.date ? moment(formik.values.date).format("MMMM DD, YYYY") : ""}
                error={(formik.touched.date && formik.errors.date) as boolean}
                errorMessage={formik.errors.date}
                errorOutline={(formik.touched.date && formik.errors.date) as boolean}
              />
            </DatepickerTooltip>
          </div>

          <Button disabled={formik.isSubmitting} type="submit" className="mx-auto">
            {externalLinks?.index != null && externalLinks.index > -1
              ? "Update Link"
              : "Create Link"}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};
