import { cn } from "@/lib/utils";
import { FormikErrors } from "formik";
import { TextareaHTMLAttributes, forwardRef } from "react";
import Label from "./label";
import { Text } from "./text";

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  showMaxlengthLabel?: boolean;
  value?: string;
  error?: boolean;
  errorOutline?: boolean;
  errorMessage?: string | string[] | FormikErrors<any> | FormikErrors<any>[] | undefined;
  label?: React.ReactNode | undefined;
  helperText?: string | undefined;

  labelClassName?: string | undefined;
  textareaClassName?: string | undefined;
  helperClassName?: string | undefined;
  errorClassName?: string | undefined;

  unstyled?: boolean;
  resizable?: boolean; // New prop for resize functionality
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    {
      className,
      value,
      maxLength,
      showMaxlengthLabel,
      error,
      errorMessage,
      errorOutline,
      label,
      helperText,
      labelClassName,
      textareaClassName,
      helperClassName,
      errorClassName,
      unstyled = false, // Default unstyled to false
      resizable = false, // Default resizable to false
      ...props
    },
    ref
  ) => {
    return (
      <div className={cn("relative w-full", className)}>
        {label ? (
          <div className=" mb-s">
            <Label className={labelClassName}>{label}</Label>
          </div>
        ) : null}
        <div className="relative w-full">
          <textarea
            className={cn(
              // "flex min-h-[80px] w-full px-l rounded-lg py-m text-sm ring-offset-background placeholder-color-tertiary focus-visible:outline-none ",
              "flex min-h-[80px] px-m outline-none py-m rounded-lg w-full disabled:cursor-not-allowed placeholder-color-tertiary disabled:opacity-50 ring-offset-background  field-border",
              {
                " bg-container": !unstyled,
                "resize-none": !resizable, // Conditional class for resizable
                resize: resizable, // Conditional class for resizable
                "border-red-500 border-1 rounded-lg border-solid": errorOutline
              },
              textareaClassName
            )}
            ref={ref}
            maxLength={maxLength}
            value={value}
            {...props}
          />
          {maxLength && showMaxlengthLabel && (
            <Text
              variant="caption_two"
              as="span"
              className="text-muted-foreground absolute bottom-2 right-2"
            >
              {`${value?.length || "0"}/${maxLength || "0"}`}
            </Text>
          )}
        </div>
        {error && errorMessage && (
          <Text as="span" className={cn("text-red-500 text-xs mx-l", errorClassName)}>
            {typeof errorMessage === "object" ? JSON.stringify(errorMessage) : errorMessage}
          </Text>
        )}
        {helperText ? (
          <Text
            as="span"
            variant="caption_one"
            className={cn(
              "text-secondary-foreground text-xs mx-l mt-s mb-s block",
              helperClassName
            )}
          >
            {helperText}
          </Text>
        ) : null}
      </div>
    );
  }
);
Textarea.displayName = "Textarea";
