"use client";

import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle
} from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import * as yup from "yup";
import { Button } from "@/components/ui/button";
import { useEffect, useState } from "react";
import { Setting2 } from "iconsax-react";
import { TextareaAutosize } from "@/components/ui/textarea-autosize";
import { useFormik } from "formik";
import { toast } from "sonner";
import clientApi from "@/api-client/client";
import { ApiResponse } from "@/types/api";
import { X } from "lucide-react";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "@/components/ui/select";
import eventClientApi from "@/api-client/event-client";

const formSchema = yup.object({
  prompt: yup.string().required("Prompt is required.")
});

const CONTENT_STYLE_TYPES = [{ label: "Playful" }, { label: "Casual" }, { label: "Formal" }];

const CONTENT_LENGTH_TYPES = [{ label: "Short" }, { label: "Medium" }, { label: "Long" }];

// Al-generated event description

export const GenerateWithAiModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { callback, aiPromtFor, initPromt } = data;
  const [showAdvanced, setShowAdvanced] = useState(false);

  const isModalOpen = isOpen && type === "generateWithAi";

  const formik = useFormik({
    initialValues: {
      prompt: "",
      contentStyle: "Playful",
      contentLength: "Short",
      description: ""
    },
    validationSchema: formSchema,
    onSubmit: async (values, { setSubmitting, setFieldValue }) => {
      try {
        if (aiPromtFor === "community") {
          const { data } = await clientApi
            .post(`api/v1/servers/generate-description-by-ai`, {
              json: {
                community_prompt: values.prompt,
                content_length: values.contentLength,
                content_style: values.contentStyle
              }
            })
            .json<ApiResponse<{ generated_output: string }>>();

          setFieldValue("description", data?.generated_output?.trim());
        }

        if (aiPromtFor === "event") {
          const response = await eventClientApi
            .post(`api/v1/proxy/events/generate/description`, {
              json: {
                event_prompt: values.prompt,
                content_length: values.contentLength,
                content_style: values.contentStyle
              }
            })
            .json<any>();

          setFieldValue("description", response?.generated_output?.trim());
        }
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    (async () => {
      if (initPromt?.trim()) {
        await formik.setFieldValue("prompt", initPromt.trim());
        formik.handleSubmit();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initPromt]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  const acceptDescription = () => {
    formik.resetForm();
    callback?.(formik.values);
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent
        className="max-w-full-50px w-[800px] overflow-hidden p-0 bg-none border-none backdrop-blur-none shadow-none"
        style={{
          height: "calc(100dvh - 90px)",
          background: "transparent"
        }}
        isShowCloseBtn={false}
      >
        <div className="w-full max-h-full flex flex-col overflow-hidden">
          <div className="gap-4 rounded-medium border-2 border-border bg-modal overflow-hidden py-4 shadow-lg backdrop-blur-20 flex flex-col my-auto">
            <div className="flex-shrink-0 px-4">
              <DialogHeader className="flex-shrink-0">
                <div className="flex items-center gap-2">
                  <DialogTitle>Write with AI</DialogTitle>
                  <div className="ms-auto flex items-center">
                    <Button
                      variant="unstyled"
                      type="button"
                      onClick={() => setShowAdvanced((v) => !v)}
                    >
                      <Setting2 size={20} />
                    </Button>
                    <DialogClose className="ms-auto">
                      <X className="h-4 w-4" />
                    </DialogClose>
                  </div>
                </div>
              </DialogHeader>
            </div>
            <div className="flex-shrink-1 overflow-hidden">
              <ScrollArea className="h-full px-4">
                <div className="w-full flex-shrink-1 flex flex-col gap-3">
                  {showAdvanced ? (
                    <div className="flex gap-3 w-full">
                      <div className="w-1/2">
                        <Select
                          value={formik.values.contentStyle}
                          onValueChange={(value) => formik.setFieldValue("contentStyle", value)}
                        >
                          <SelectTrigger className="rounded-4">
                            <SelectValue placeholder="Style" />
                          </SelectTrigger>
                          <SelectContent className="border border-solid border-gray-200">
                            {CONTENT_STYLE_TYPES.map((data, index) => (
                              <SelectItem value={data.label} key={index}>
                                {data.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                      <div className="w-1/2">
                        <Select
                          value={formik.values.contentLength}
                          onValueChange={(value) => formik.setFieldValue("contentLength", value)}
                        >
                          <SelectTrigger className="rounded-4">
                            <SelectValue placeholder="Length" />
                          </SelectTrigger>
                          <SelectContent className="border border-solid border-gray-200">
                            {CONTENT_LENGTH_TYPES.map((data, index) => (
                              <SelectItem value={data.label} key={index}>
                                {data.label}
                              </SelectItem>
                            ))}
                          </SelectContent>
                        </Select>
                      </div>
                    </div>
                  ) : null}

                  <TextareaAutosize
                    placeholder="Write a detailed prompt for our AI to help it generate description that best matches with your intent."
                    value={formik.values.prompt}
                    name="prompt"
                    onChange={formik.handleChange}
                    minRows={2}
                    label="Prompt"
                  />
                  {formik.values.description ? (
                    <TextareaAutosize
                      value={formik.values.description}
                      name="description"
                      onChange={formik.handleChange}
                      label="Suggestion"
                    />
                  ) : null}
                </div>
              </ScrollArea>
            </div>
            <div className="flex-shrink-0 px-4">
              <div className="flex items-center justify-center gap-2 flex-shrink-0 relative">
                <Button
                  disabled={formik.isSubmitting}
                  loading={formik.isSubmitting}
                  loaderProps={{ size: "xs" }}
                  onClick={() => formik.handleSubmit()}
                  variant="secondary"
                  type="button"
                >
                  {formik.values.description ? "Retry" : "Generate"}
                </Button>
                {formik.values.description ? (
                  <Button disabled={formik.isSubmitting} onClick={acceptDescription} type="button">
                    Accept
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};
