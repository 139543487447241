"use client";

import * as yup from "yup";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useModal } from "@/stores/use-modal-store";
import { useFormik } from "formik";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import clientApi from "@/api-client/client";
import { toast } from "sonner";
import { ApiResponse } from "@/types/api";
import { Fragment, useEffect } from "react";
import { Text } from "@/components/ui/text";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";

const Schema = yup.object({
  reason: yup.string().trim().optional()
});

const options = {
  "General Decline": "Registration declined.",
  "Criteria Not Met": "Does not meet membership criteria.",
  "Capacity Limitation": "Unable to accommodate due to capacity limitations.",
  "Insufficient Information": "Declined due to insufficient information.",
  Other: ""
};

export const RejectMemberModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const { callback, member } = data;

  const isModalOpen = isOpen && type === "rejectMember";

  const formik = useFormik({
    initialValues: {
      reason: "",
      memberId: "",
      slectedReason: "General Decline"
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        const payload = { ...values };
        if (values.slectedReason === "Other") payload.reason = values.reason;
        // @ts-ignore
        else payload.reason = options[values.slectedReason];

        const { message } = await clientApi
          .put(`api/v1/${member?.serverId}/members/reject`, {
            json: payload
          })
          .json<ApiResponse>();
        callback?.();
        resetForm();
        onClose();
        toast.success(message);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (isOpen && member) formik.setFieldValue("memberId", member.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, member]);

  const handleClose = () => {
    formik.resetForm();
    onClose();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4">
        <DialogHeader className="sticky">
          <DialogTitle>Reason for Rejection</DialogTitle>
        </DialogHeader>

        <form onSubmit={formik.handleSubmit} className="space-y-4">
          <div className="flex flex-col w-full">
            <Text as="span" variant="body_one_strong" className="mb-4">
              Select Reason
            </Text>

            <RadioGroup
              className="flex flex-col gap-4"
              onValueChange={(value) => formik.setFieldValue("slectedReason", value)}
            >
              {Object.keys(options).map((option, index) => (
                <div className="flex items-center gap-4" key={index}>
                  <RadioGroupItem
                    value={option}
                    checked={formik.values.slectedReason === option}
                    size="medium"
                    className="flex-shrink-0"
                  />
                  <div className="flex flex-col w-full flex-shrink-1">
                    {option === "Other" ? (
                      <Fragment>
                        <Textarea
                          name="reason"
                          maxLength={250}
                          placeholder="Specify Other Reason"
                          onChange={formik.handleChange}
                          value={formik.values.reason}
                          error={(formik.touched.reason && formik.errors.reason) as boolean}
                          errorMessage={formik.errors.reason}
                          errorOutline={(formik.touched.reason && formik.errors.reason) as boolean}
                          unstyled
                          readOnly={formik.values.slectedReason !== "Other"}
                        />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Text as="span" variant="body_one_strong">
                          {option}
                        </Text>
                        <Text as="span" variant="caption_one" className="text-color-secondary">
                          {/* @ts-ignore */}
                          {options[option]}
                        </Text>
                      </Fragment>
                    )}
                  </div>
                </div>
              ))}
            </RadioGroup>
            {/*  */}
          </div>
          <div className="flex justify-center gap-2">
            <Button
              disabled={formik.isSubmitting}
              type="button"
              onClick={onClose}
              variant="secondary"
            >
              Cancel
            </Button>
            <Button
              disabled={formik.isSubmitting}
              loading={formik.isSubmitting}
              loaderProps={{ size: "xs" }}
              type="submit"
            >
              Reject
            </Button>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};
