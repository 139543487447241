import React, { memo, useState } from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import Picker from "@emoji-mart/react";
import data from "@emoji-mart/data";

type EmojiPickerProps = {
  children?: React.ReactElement;
  EmojiPickerClassName?: string;
  onChange: (value: string) => void;
} & Partial<TippyProps>;

const EmojiPicker = memo(
  ({ children, EmojiPickerClassName, onChange, ...props }: EmojiPickerProps) => {
    const [visible, setVisible] = useState(false);

    return (
      <Tippy
        arrow={false}
        interactive
        content={
          <div className="relative">
            {visible ? (
              <Picker
                theme="light"
                data={data}
                onEmojiSelect={(emoji: any) => onChange(emoji.native)}
                className={EmojiPickerClassName}
              />
            ) : null}
          </div>
        }
        theme="key"
        className="!bg-transparent p-0 emoji-popover pointer-events-auto"
        {...props}
        onShow={() => setVisible(true)}
        onHide={() => setVisible(false)}
      >
        {children}
      </Tippy>
    );
  }
);

export default EmojiPicker;
