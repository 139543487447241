/* eslint-disable @next/next/no-img-element */
"use client";

import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useParams } from "next/navigation";
import { useModal } from "@/stores/use-modal-store";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import IconContainer from "@/components/ui/icon-container";
import { IconX } from "@tabler/icons-react";
import { DocumentUpload } from "iconsax-react";
import { Dropzone } from "@/components/ui/dropzone";
import { Text } from "@/components/ui/text";
import Box from "@/components/ui/box";
import { useListState } from "@mantine/hooks";
import { FileListState } from "@/types/thread";
import { useFormik } from "formik";
import * as yup from "yup";
import { toast } from "sonner";
import { getFileType, uploadFile } from "@/lib/uploadthing";
import { AttachmentType, HomeSections } from "@key.ai/enum";
import { EndPoints } from "@/types/uploadthing";
import { Attachment } from "@/types/entities";
import clientApi from "@/api-client/client";
import { useCallback, useEffect, useState } from "react";
import { ApiResponse } from "@/types/api";
import { v4 as uuidv4 } from "uuid";
import { MIME_TYPES } from "@/types/mime-types";
import { maxFiveMb } from "@/config/max-size";

const Schema = yup.object({
  title: yup.string().max(100, "Max 100 characters allowed.")
});

export const PhotoGalleryModal = () => {
  const { isOpen, onClose, type, data } = useModal();
  const params = useParams<{ domain: string }>();

  const { gallery, callback } = data;

  const [images, handlers] = useListState<FileListState>([]);
  const isModalOpen = isOpen && type === "photoGallery";

  const [ids, setIds] = useState<string[]>([]);

  const imgRemove = useCallback(
    (id: string) => {
      const set = new Set(ids || []);
      set.add(id);
      setIds([...Array.from(set)]);
    },
    [ids]
  );

  const formik = useFormik({
    initialValues: {
      title: "",
      clientId: "",
      section: HomeSections.PhotoGallery
    },
    validationSchema: Schema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        if (!images.length) return toast.error("Please select at least one image.");
        const attachments: Partial<Attachment>[] = [];
        for (const _file of images) {
          const attachment: Partial<Attachment> = {};
          if (_file.blob) {
            const upload = await uploadFile(_file.blob, EndPoints.Section);
            attachment["fileName"] = _file.blob.name;
            attachment["fileType"] = getFileType(_file.blob);
            attachment["mimeType"] = _file.blob.type;
            attachment["type"] = AttachmentType.SERVER_SECTIONS;
            attachment["url"] = upload.url;
            attachment["fileKey"] = upload.fileKey;
            attachment["size"] = _file.blob.size;
            attachments.push(attachment as Attachment);
          }
        }

        for (const id of ids) {
          await clientApi
            .delete(`api/v1/attachments`, {
              searchParams: {
                id: id,
                type: AttachmentType.SERVER_SECTIONS,
                serverId: params.domain
              },
              json: {}
            })
            .then()
            .catch();
        }

        setIds([]);

        const payload = {
          ...values,
          order: Number(gallery?.order),
          attachments
        };

        await clientApi
          .post(`api/v1/${params.domain}/server-sections`, { json: payload })
          .json<ApiResponse>();

        callback?.({
          title: values.title,
          clientId: values.clientId,
          attachments: images
        });
        handleClose();
        toast.success("Saved!");
      } catch (error) {
        toast.error(error.message);
      } finally {
        setSubmitting(false);
      }
    }
  });

  useEffect(() => {
    if (gallery && isOpen) {
      formik.setFieldValue("title", gallery.section.title);
      formik.setFieldValue("clientId", gallery.clientId || uuidv4());

      handlers.setState(
        (gallery.section?.attachments || []).map((attachment) => ({
          blob: null,
          url: attachment.url,
          liveUrl: attachment.url,
          fileName: attachment.fileName,
          fileType: attachment.fileType,
          id: attachment.id,
          mimeType: attachment.mimeType,
          type: attachment.type,
          size: attachment.size
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gallery]);

  const selectImages = async (files: File[]) => {
    const f = [...images];
    for (const file of files) {
      f.push({
        blob: file,
        url: URL.createObjectURL(file),
        mimeType: file.type,
        fileName: file.name
      });
    }
    handlers.setState(f);
  };

  const handleClose = () => {
    onClose();
    handlers.setState([]);
    formik.resetForm();
  };

  return (
    <Dialog open={isModalOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-full-50px w-[550px] p-4 max-h-[90%] gap-2xl">
        <DialogHeader className="sticky">
          <DialogTitle>Photo Gallery</DialogTitle>
        </DialogHeader>

        <Input
          placeholder="Section Title"
          variant="large"
          unstyled
          className="font-semibold text-2xl"
          onChange={formik.handleChange}
          value={formik.values.title}
          name="title"
          error={(formik.touched.title && formik.errors.title) as boolean}
          errorMessage={formik.errors.title}
          errorOutline={(formik.touched.title && formik.errors.title) as boolean}
        />

        <Dropzone
          accept={{
            [MIME_TYPES.png]: [],
            [MIME_TYPES.gif]: [],
            [MIME_TYPES.jpeg]: [],
            [MIME_TYPES.svg]: [],
            [MIME_TYPES.webp]: [],
            [MIME_TYPES.avif]: [],
            [MIME_TYPES.heic]: []
          }}
          maxSize={maxFiveMb}
          onDrop={(files) => selectImages(files)}
          handleImg={(file) => file && selectImages([file])}
          cropRatio="PROFILE"
        >
          <Box className="flex flex-col items-center border-dashed border-1 border-color-primary h-[250px] p-5">
            <div className="flex flex-col my-auto">
              <Button
                type="button"
                variant="unstyled"
                className="border-brand-100 border-1"
                layerClassName="flex items-center gap-3"
              >
                <DocumentUpload size={25} />
                <Text variant="caption_one_strong">Drag & Drop Files</Text>
              </Button>
              <Text variant="caption_one" className="mt-3">
                Each file should not exceed 5MB
              </Text>
            </div>
          </Box>
        </Dropzone>

        <div className="grid grid-cols-5 gap-3">
          {images.map((img, index) => (
            <div className="relative" key={index}>
              <img
                src={img.url}
                alt=""
                className="object-cover h-[90px] rounded-sm overflow-hidden w-full"
              />
              <IconContainer
                variant="unstyled"
                type="button"
                size={16}
                className="bg-danger-100 rounded-full absolute -top-1 -right-1"
                icon={<IconX size={15} />}
                onClick={() => {
                  if (img.id) imgRemove(img.id);
                  handlers.remove(index);
                }}
              />
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center gap-2xl">
          <Button
            variant="secondary"
            disabled={formik.isSubmitting}
            type="button"
            className="w-[180px]"
            onClick={() => handleClose()}
          >
            Cancel
          </Button>

          <Button
            className="w-[180px]"
            disabled={formik.isSubmitting}
            loading={formik.isSubmitting}
            type="button"
            onClick={() => formik.handleSubmit()}
            loaderProps={{ size: "xs" }}
          >
            Save
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
