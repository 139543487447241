import(/* webpackMode: "eager" */ "/app/apps/web/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/components/datadog.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/components/intercom-messenger.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/components/PostHogPageView.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/app/apps/web/components/ui/sonner.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/node_modules/quill/dist/quill.snow.css");
;
import(/* webpackMode: "eager", webpackExports: ["CryptoProvider"] */ "/app/apps/web/providers/crypto-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FingerprintProvider"] */ "/app/apps/web/providers/fingerprint-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ModalProvider"] */ "/app/apps/web/providers/modal-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PHProvider"] */ "/app/apps/web/providers/posthog-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["QueryProvider"] */ "/app/apps/web/providers/query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/apps/web/providers/session-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SocketProvider"] */ "/app/apps/web/providers/socket-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/web/theme.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/shared/lib/lazy-dynamic/preload-css.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"DM_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"100\",\"200\",\"300\",\"400\",\"500\",\"600\",\"700\",\"800\",\"900\"]}],\"variableName\":\"font\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-phone-number-input/style.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/tippy.js/dist/tippy.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-advanced-cropper/dist/style.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-big-calendar/lib/css/react-big-calendar.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/react-big-calendar/lib/addons/dragAndDrop/styles.css");
